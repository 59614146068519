const ru = {
    "Russian":"Русский",
    "English":"Английский",
    "Turkish":"Турецкий",
    "Greek": "Греческий",
    "Deutsch": "Немецкий",
    "RussianCountry":"Россия",
    "EnglishCountry":"США",
    "TurkishCountry":"Турция",
    "GreekCountry": "Греция",
    "DeutschCountry": "Германия",
    "Post Your Ad":"Объявление",
    "Contact": "Контакты",
    "Language": "Язык",
    "Home": "Главная",
    "MainSlogan": "Покупайте, продавайте, арендуйте и обменивайте в один клик",
    "MainSloganSubtitle": "Бесплатный поиск по более чем 2000 активным объявлениям в более чем 29 категориях",
    "Select location": "Местоположение",
    "Select Category": "Выбор категории",
    "Enter Keyword here": "Поиск...",
    "Search": "Поиск",
    "Last news": "Последние новости",
    "The best places": "Лучшие места",
    "Recent announcements": "Последние объявления",
    "Open the ad catalog": "Открыть каталог объявлений",
    "ad catalog": "Все объявления",
    "All": "Все",
    "Recommended": "Рекомендованные",
    "New advs": "Новые",
    "Popular advs": "Популярные",
    "Ad Categories": "Категории объявлений",
    "Login/Register": "Вход/регистрация",
    "Auto": "Авто",
    "Realty": "Недвижимость",
    "Work": "Работа",
    "Services": "Услуги",

    "Filter": "Фильтр",
    "Type": "Тип",
    "Category": "Рубрика",
    "Location": "Регион",
    "Price Range": "Диапазон цен",
    "Apply Filters": "Применить фильтр",
    "Showing": "Отображаются",
    "of": "из",
    "results": "результатов",

    "Sort By": "Сортировать",
    "A to Z (title)":"А -> Я (заголовок)",
    "Z to A (title)":"Я -> А (заголовок)",
    "Data Added (oldest)":"Дата добавления",
    "Most Viewed":"Частые просмотры",
    "Less Viewed":"Редкие просмотры",
    "Price (low to high)":"Цена (от низкой к высокой)",
    "Price (high to low)":"Цена (от высокой к низкой)",
    "Next": "Следующая",
    "Previous": "Предыдущая",
    "New": "Новый",
    "Show Phone No": "Номер телефона",
    "Add to Favourites": "Добавить в закладки",
    "Sell": "Продажа",
    "views": "просмотры",
    "Details": "Подробности",
    "Features": "Особенности",
    "Report Abuse": "Сообщить о злоупотреблении",
    "Share": "Поделиться",
    "Related Ads": "Похожие объявления",
    "Leave a Review": "Оставить отзыв",
    "Your email address will not be published. Required fields are marked": "Ваш адрес электронной почты не будет опубликован. Обязательные поля отмечены",
    "Your Name": "Имя",
    "Your Email": "Email",
    "Review Title": "Заголовок отзыва",
    "Your Review": "Отзыв",
    "SUBMIT": "ОТПРАВИТЬ",
    "LOAD MORE": "ПОКАЗАТЬ ЕЩЕ",
    "Seller Information": "Продавец",
    "Click to reveal phone number": "Нажмите, чтобы показать номер телефона",
    "Email to Seller": "Написать Продавцу",
    "Safety Tips for Buyers": "Советы по технике безопасности для покупателей",
    "Meet seller at a public place": "Встречайтесь с продавцом в общественном месте",
    "Check The item before you buy": "Проверьте товар перед покупкой",
    "Pay only after collecting The item": "Платите только после получения товара",

    "Dollar": "Доллар",
    "Euro": "Евро",
    "Ruble": "Рубль",
    "Pound": "Фунт",
    "Lira": "Лира",

    "Dashboard": "Аналитика",
    "My Listings": "Мои объявления",
    "Store": "Магазин",
    "Favourites": "Отложенные",
    "Payments": "Платежи",
    "Account details": "Профиль",
    "Logout": "Выход",

    "Login": "Войти",
    "Username or E-mail": "Имя пользователя или e-mail",
    "Password": "Пароль",
    "Remember Me": "Запомнить меня",
    "Forgot your password": "Забыли ваш пароль",
    "Register": "Регистрация",
    "Username cannot be changed": "Имя пользователя можно изменить в профиле",
    "Email address": "Email адрес",
    "Username": "Имя пользователя",
    "Phone": "Телефон",
    "Repeat password": "Повтор пароля",

    "Sub Category" : "Подрубрика",
    "Next step": "Далее",
    "Send for verification": "Отправить на проверку",
    "Product Information": "Общая информация о продукте",
    "Title": "Заголовок",
    "Price Type" : "Тип стоимости",
    "Fixed": "Фиксированная",
    "Negotiable": "Договорная",
    "On Call": "По звонку",
    "Price": "Цена",
    "Condition": "Состояние товара",
    "Used": "Б/у",
    "Brand": "Торговая марка",
    "Authenticity": "Подлинность",
    "Original": "Оригинал",
    "Copy": "Копия",
    "Description": "Описание",
    "Features List": "Список функций",
    "Images": "Изображения",
    "Drop files here to add them.": "Перетащите файлы сюда чтобы их добавить.",
    "Browse files": "Найти файлы",
    "Recommended image size to": "Рекомендуемое разрешение изображения",
    "Image maximum size": "Максимальный размер файла",
    "Allowed image type": "Форматы изображений",
    "You can upload up to 10 images": "Вы можете загрузить до 10 изображений.",
    "Contact Details": "Контакты",
    "City": "Город",
    "Zip Code": "Почтовый код",
    "Address": "Адрес",
    "Whatsapp Number": "Whatsapp номер",
    "Email" : "Email",
    "Map": "Карта",
    "Don't show the Map": "Не показывать карту",
    "Ad Type": "Тип объявления",
    "Personal car": "Личный автомобиль",
    "Car bought for sale": "Автомобиль приобретен для продажи",
    "Color": "Цвет",
    "Video": "Видео",
    "Mileage": "Пробег",
    "Сar condition": "Состояние авто",
    "Not broken": "Не битый",
    "Broken": "Битый",
    "VIN or body number": "VIN или номер кузова",
    "Government number": "Номер",
    "Marka": "Марка",
    "Model": "Модель",
    "Year of issue": "Год выпуска",
    "Body": "Кузов",
    "Generation": "Поколение",
    "Engine's type": "Тип двигателя",
    "CarDrive": "Привод",
    "Transmission": "Коробка передач",
    "Rudder position": "Положение руля",
    "Prev step": "Назад",
    "Save and exit": "Сохранить и выйти",

    "Basic Information": "Основная информация",
    "Account": "Имя пользователя",
    "First Name": "Имя",
    "Last Name": "Фамилия",
    "Change Password": "Изменить пароль",
    "WhatsApp Phone": "WhatsApp телефон",
    "Website": "Веб-сайт",
    "State": "Регион/штат",
    "Country": "Страна",
    "Update Account": "Обновить данные",
    "Store Images": "Изображения для магазина",
    "Store Banner": "Баннер",
    "Store Logo": "Логотип",
    "Store Schedule": "Раписание",
    "Opening Hours": "Часы работы",
    "Always open": "Открыть круглосуточно",
    "Select Opening Hours": "Указать чаcы работы",
    "Store Information": "Информация о магазине",
    "Name": "Название",
    "Slogan": "Слоган",
    "Socials": "Социальные сети",
    "Update Store": "Обновить магазин",
    "Maximum file size": "Максимальный размер файла",
    "My goods": "Мои товары",
    "Membership Report": "Состояние аккаунта",
    "Status": "Статус",
    "Active": "Активен",
    "Blocked": "Заблокирован",
    "Created": "Создан",
    "Ads created": "Создано объявлений",
    "Finished transactions": "Завершено сделок",
    "Items in the store": "Товаров в магазине",
    "Goods sold": "Продано товаров",
    "Property": "Недвижимость",
    "Places an ad": "Размещает объявление",
    "Owner": "Владелец",
    "Mediator": "Посредник",
    "Developer": "Застройщик",
    "Housing type": "Тип недвижимости",
    "Flat": "Квартира",
    "Apartments": "Апартаменты",
    "House": "Дом",
    "Property condition": "Состояние недвижимости",
    "Level": "Этаж",
    "Rooms": "Кол-во комнат",
    "Studio": "Студия",
    "10 rooms and more": "10 комнат и более",
    "Free layout": "Свободная планировка",
    "Balcony or loggia": "Балкон или лоджия",
    "Balcony": "Балкон",
    "Loggia": "Лоджия",
    "Wardrobe": "Гардероб",
    "Panoramic windows": "Панорамные окна",
    "Square": "Площадь",
    "Total space": "Общая",
    "Living space": "Жилая",
    "Ceiling height": "Высота потолков",
    "Bathroom": "Санузел",
    "Combined": "Совмещенный",
    "Separated": "Раздельный",
    "Need": "Требуется",
    "Cosmetic": "Косметический",
    "Designer": "Дизайнерский",
    "Repair": "Ремонт",
    "Windows": "Окна",
    "To the yard": "Во двор",
    "To the street": "На улицу",
    "To the sunny side": "На солнечную сторону",
    "Furniture": "Мебель",
    "Kitchen": "Кухня",
    "Clothes storage": "Хранение одежды",
    "Sleeping places": "Спальные места",
    "Technics": "Техника",
    "Air conditioning": "Кондиционер",
    "Fridge": "Холодильник",
    "Washing machine": "Стиральная машина",
    "Dishwasher": "Посудомоечная машина",
    "Water heater": "Водонагреватель",
    "About home": "О доме",
    "House type": "Тип дома",
    "Brick": "Кирпичный",
    "Panel": "Панельный",
    "Blocky": "Блочный",
    "Monolithic": "Монолитный",
    "Monolithic - brick": "Монолитно - кирпичный",
    "Wooden": "Деревянный",
    "Concierge": "Консьерж",
    "Garbage chute": "Мусоропровод",
    "Gas": "Газ",
    "Year of construction": "Год постройки",
    "Floors in the house": "Этажей в доме",
    "Passenger lift": "Пассажирский лифт",
    "Cargo lift": "Грузовой лифт",
    "None": "Нет",
    "Yard": "Двор",
    "Parking": "Парковка",
    "Closed area": "Закрытая площадка",
    "Playground": "Детская площадка",
    "Sports ground": "Спортивная площадка",
    "Underground": "Подземная",
    "Ground multilevel": "Наземная многоуровневая",
    "Open in the yard": "Открытая во дворе",
    "Behind the gate in the yard": "За шлакбаумом во дворе",
    "Select a category": "Укажите категорию",
    "Select a subcategory": "Укажите субкатегорию",
    "slogan": "Слоган в подвале сайта",
    "HeaderLinks1Title": "Как продавать быстро",
    "HeaderLinks2Title": "Информация",
    "HeaderLinks3Title": "Помощь и поддержка",
    "cantcreateads": "Вы не можете создавать объявления пока не прошли регистрацию!",
    "cantcreateads_blocked": "Вы не можете создавать объявления пока ваш аккаунт заблокирован!",
    "RegisterOrLogin": "Авторизоваться",
    "notset": "Не задано",

    "mainpage_title": "AVGU - доска объявлений",
    "new Ad": "Новое объявление",
    "Main Page": "Главная",
    "Personal cabinet": "Кабинет",
    "No data": "Данных нет",
    "wheelLeft": "Руль слева",
    "wheelRight": "Руль справа",
    "Currency": "Валюта",
    "NewAd": "Новое объявление",
    "NewAdMessage": "Ваше объявление отправлено на модерацию. После проверки оно будет опубликовано на сайте.",
    "Write a feature in each line eg": "Напишите особенность в каждой строке, например",
    "Feature": "Особенность",
    "My Account": "Мой аккаунт",
    "Published": "Опубликовано",
    "Hidden": "Скрыто",
    "Remove from publish": "Удалить из публикации",
    "Publish": "Опубликовать",
    "Delete": "Удалить",
    "Reviews list": "Список отзывов",
    "Longitude": "Долгота",
    "Latitude": "Широта",
    "Edit draft": "Редактировать черновик",
    "Edit ad": "Редактировать объявление",
    "photos not uploaded": "Фотографии не загружены",
    "Loading": "Загрузка",
    "Ad Cars Details": "Детали объявления автомобили",
    "Ad Prop Details": "Детали объявления недвижимость",
    "Left Rudder": "Руль слева",
    "Right Rudder": "Пуль справа",
    "Clear Filters": "Очистить фильтр",
    "Select Country": "Выберите страну",
    "Select State/Region": "Выберите область",
    "Select City": "Выберите город",
    "Country not selected": "Страна не выбрана",
    "Region/State not selected": "Область не выбран",
    "City not selected": "Город не выбран",
    "Color not selected": "Цвет не выбран",
    "Car Ad type not set": "Тип объявления не выбран",
    "Mileage not set": "Пробег не задан",
    "VIN or body number not set": "VIN или номер кузова не задан",
    "Price Type not set": "Тип цены не задан",
    "Currency not set": "Валюта не задана",
    "Cost not set": "Цена не задана",
    "View Store": "Посмотреть магазин",
    "Rating": "Рейтинг",
    "Remove from Favourites": "Удалить из избранного",
    "Deal completed": "Сделка завершена",
    "Yes": "Да",
    "No": "Нет",
    "Visit Website": "Посетить сайт",
    "Published Ads": "Опубликованные объявления",
    "Registered Users": "Зарегистрированные пользователи",
    "Verified Users": "Проверенные пользователи",
    "Ads": "Объявлений",
    "Info": "Информация",
    "Open news": "Открыть новости",
    "Total ads": "Всего объявлений",
    "Total users": "Всего пользователей",
    "Total shops": "Всего магазинов",
    "Total deals": "Всего сделок",
    "Rules for posting ads": "Правила размещения объявлений",
    "Advertising": "Реклама",
    "Open locations": "Открыть места",
    "Contacts": "Контакты",
    "In start": "В начало",
    "All category": "Все категории",
}

export default ru