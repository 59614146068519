import store from './redux/storeFactory'
import {RootState, AppDispatch, DataLoader} from './redux/storeFactory'

import React, {createContext, useEffect, useState} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Store } from 'react-notifications-component';
import { Provider } from 'react-redux'
import * as mapboxgl from 'mapbox-gl';

import { createUploadLink } from "apollo-upload-client"; 
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import {split} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import {
  ApolloProvider, createHttpLink, ApolloClient, InMemoryCache, from, HttpLink, useLazyQuery, ApolloLink
} from "@apollo/client"; 
import { setContext } from '@apollo/client/link/context'; 
import { onError } from "@apollo/client/link/error";
import graphql from "./gql/gqlqueries";

import logo from './logo.svg';
import './App.css';

import MainPage from '../src/bb/mainboard'

import ru from './bb/langs/ru'
import en from './bb/langs/en'
import tur from  './bb/langs/tur'
import greek from  './bb/langs/greek'
import deutsch from  './bb/langs/deutsch'

import { Context } from 'vm';
import {User} from './models/User'
import {Store as AvguStore} from './models/Store'
import ReduxActions from './redux/reducers/actions';
const langs = {"ru": ru, "en": en, "tur": tur, "greek": greek, "deutsch": deutsch}
const activeLang = localStorage.getItem("lang")==null ? "en" : localStorage.getItem("lang")?.toString()
const currency = localStorage.getItem("currency")==null ? "Dollar" : localStorage.getItem("currency")?.toString()


function GetLangData(lang:string)
{
    switch (lang)
    {
      case "ru":
        return langs.ru
      case "en":
        return langs.en
      case "tur":
        return langs.tur
      case "greek":
        return langs.greek
      case "deutsch":
        return langs.deutsch
    }

    return langs.en
}

  type LangDataType = { [id: string]: string }

type AppContextData = 
{
    lang: string 
    langdata: LangDataType
    currentDate: Date
    currency: string
    country: string
    logged: Boolean
    client:any
    token: string
    profile?: User
    store?: AvguStore
    dataStore: any
    state: RootState
    dispatch: AppDispatch
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    }
  }
});  

const apolloauthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');
  operation.setContext((context) =>({
    headers: {
      ...context.headers,
      authorization: token ? `${token}` : "",
    }
  }));
  return forward(operation);
})


const errorLink = onError(({ graphQLErrors, networkError }) => {

  if (graphQLErrors)
  {
    graphQLErrors.forEach(({ message, locations, path }) =>
    {
        Store.addNotification({
          title: "Ошибки сервера!",
          message: message,
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        })

        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      });
  }
  
  if (networkError) console.log(`[Network error]: ${networkError}`);

});


const httpLink = new HttpLink({
  uri: "https://api.avgu.com"
});

var uploadLink = createUploadLink({uri: 'https://api.avgu.com'}) 

const httpAuthLink = apolloauthLink.concat(httpLink).concat(errorLink)


const wsLink = new GraphQLWsLink(createClient({
  url: 'wss://subscriptions.avgu.com',
  connectionParams: {
    authToken: localStorage.getItem('token'),
    reconnect: true
  },
}));


const uploadersplitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    console.log(definition)
    return (
      definition.kind === 'OperationDefinition' &&
      (definition.name?.value === 'UploadFiles' || definition.name?.value === 'UploadAvatar' || definition.name?.value === 'UploadProjectFiles')
    );
  },
  uploadLink,
  httpAuthLink,
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    console.log(definition)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  uploadersplitLink,
);

const client = new ApolloClient({
  link: from([splitLink, uploadLink]),
  cache: new InMemoryCache()
});

//httpLink
/*const client = new ApolloClient({
  link: from([errorLink, authLink, uploadLink]),
  credentials: 'include',
  cache: new InMemoryCache()
});*/

var startContext:AppContextData = {
   currency: localStorage.getItem("currency")==null ? "Dollar" : localStorage.getItem("currency")?.toString()!,
   country:localStorage.getItem("country")==null ? "eng" : localStorage.getItem("country")?.toString()!,
   lang: localStorage.getItem("lang")==null ? "en" : localStorage.getItem("lang")?.toString()!,
   currentDate: new Date(),
   langdata: GetLangData(activeLang!),
   logged: localStorage.getItem("token")!=undefined,
   token: localStorage.getItem("token") ?? "",
   client:client,
   dataStore: store,
   state: store.getState(),
   dispatch: store.dispatch
}

var AppContext = createContext(startContext)

const App = () => {

  const [appstate, setAppState] = useState(startContext)
  
  const geolocationAPI = navigator.geolocation;

  const getUserCoordinates = () => {
    if (!geolocationAPI) {
      console.log("Geolocation is not supported by this browser.");
    } else {
      geolocationAPI.getCurrentPosition((position) => {
        const { coords } = position;
        store.dispatch(ReduxActions.regions.setActiveLocation({lat: coords.latitude, lng: coords.longitude}))
      }, (error) => {
        console.log(error);
      })
    }
  }

  useEffect(() => {
    getUserCoordinates()
  }, [])

  const setActiveLanguage = (activelang:string):void =>
  {
    var newState = appstate
    newState.lang = activelang
    newState.langdata = GetLangData(activelang)
    setAppState({...newState})
  }

  const setActiveCurrency = (currency:string):void =>
  {
    var newState = appstate
    newState.currency = currency
    setAppState({...newState})
  }

  const setActiveCountry = (country:string):void =>
  {
    var newState = appstate
    newState.country = country
    setAppState({...newState})
  }

  const setActiveUser = (user:User):void =>
  {
    var newState = appstate
    newState.profile = user
    newState.logged = true
    setAppState({...newState})
  }

  const Logout = ():void=>
  {
    localStorage.removeItem("token")
    var newState = appstate
    newState.profile = undefined
    newState.logged = false
    newState.token = ""
    setAppState({...newState})
  }

  const SetStore = (store:AvguStore):void =>
  {
    var newState = appstate
    newState.store = store
    setAppState({...newState})
  }

  const UploadDone = ()=>
  {
    var ev = new Event('update_carousels', { bubbles: true});
    document.dispatchEvent(ev);
  }

  mapboxgl.accessToken = 'pk.eyJ1IjoiaXZrb21lIiwiYSI6ImNsOGN5Mm96NTBuanczdnE5dzAxNXV3eWUifQ.y7jzCxJf2lsg5hFTaKH_VQ';

  return (
    <ApolloProvider client={client}>
    <BrowserRouter>
        <AppContext.Provider value={appstate}>
          <DataLoader done={UploadDone} UserRestore={setActiveUser} UserUnlogged={Logout} />
          <ReactNotifications />
          <Provider store={store}>
            <MainPage OnLangChange={setActiveLanguage} OnCurrencyChange={setActiveCurrency} OnCountryChange={setActiveCountry} OnLogin={setActiveUser} OnLogout={Logout} SetUserStore={SetStore}></MainPage>
          </Provider>
        </AppContext.Provider>
    </BrowserRouter>
    </ApolloProvider>
  );
}

export {AppContext}
export type {AppContextData}
export default App;