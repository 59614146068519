/* eslint import/no-webpack-loader-syntax: off */

import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from 'react-redux'
import { Routes, Route, Link, useParams } from "react-router-dom";
//import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import * as mapboxgl from 'mapbox-gl';
import {
  ApolloProvider, createHttpLink, ApolloClient, InMemoryCache, from, HttpLink, useLazyQuery, ApolloError, useMutation
} from "@apollo/client"; 

import Resizer from "react-image-file-resizer";
import Moment from 'react-moment';
import { AppContext, AppContextData } from "../../App";
import { AdItem, PhotoAlbum, PhotoItem, Review } from "../../models/AdItem";
import { ReviewItem } from "../../models/ReviewItem";
import { GoodItem, GetAddress, GetCurrencySymvol } from "../account/gooditem";
import useAd, {useSubscribe} from "../../hooks/adhooks";
import useReviews from "../../hooks/reviewshooks";
import { CategoryItem } from "../../models/Category";
import { Generation, Marka, Model } from "../../models/CarMarka";
import { ConvertAdCurrency, currencyFormat } from "../productsgrid";
import { AdFavorites, User } from "../../models/User";
import {AdViewHeader} from '../shopview/shopview'

import graphql from "../../gql/gqlqueries"
import ReduxActions from '../../redux/reducers/actions';

interface IAdViewHeader {
  item: AdItem;
}


interface IAdViewLightBox {
  item: AdItem;
}

const LighBoxGallery = (props: IAdViewLightBox) => {

  var [previews, SetPreviews] = useState<string[]>([]);
  var [photos, SetPhotos] = useState<string[]>([]);

  const resizeFile = (file:any, width:number, height:number) =>  new Promise((resolve) => {
    Resizer.imageFileResizer(
    file,
    width,
    height,
    "JPEG",
    100,
    0,
    (uri:any) => {
        resolve(uri);
    },
    "bse64"
    );
});


  async function getFileFromUrl(url:string, name:string, defaultType = 'image/jpeg'){
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  useEffect(() => {
  /*
      var previewsArray:string[] = [];

        props.item?.photos?.map(async (photo: string, index: number) => {
            var fileData = await getFileFromUrl(`/media/resources/${props.item?.id}/${photo}?`+(new Date().toDateString()), photo)
            var previewimage = await resizeFile(fileData, 160, 110) as string
            previewsArray.push(previewimage);
        });
      
      SetPreviews(previewsArray)
        */
  }, [props.item, props.item?.photos]);

  return (
    <div className="item-img-gallery">
      
      <div className="tab-content">
        {props.item.photos?.map((photo: string, index: number) => {
            return  <div
            className={"tab-pane fade " + (index == 0 ? "show active" : "")}
            id={"gallery"+index}
            role="tabpanel"
            key={"photo_"+index}
          >
            <a href="javascript:void(0)">
              <img className="zoom_01" src={`/media/resources/${props.item?.id}/${photo}?`} alt="product" data-zoom-image={`/media/resources/${props.item?.id}/${photo}?`} />
            </a>
          </div>
        })}
      </div>
    
      <ul className="nav nav-tabs" role="tablist">
        {props.item.photos?.map((photo: string, index: number) => {
          return <li className="nav-item" key={"preview_"+index}>
          <a
            className={"nav-link " + (index == 0 ? "active" : "")}
            data-toggle="tab"
            href={"#gallery"+index}
            role="tab"
            aria-selected="true"
          >
            <img style={{width: "auto", height: "110px", overflow: "hidden"}}
              src={`/media/resources/${props.item?.id}/${photo}?`}
              alt="thumbnail"
            />
          </a>
        </li>
        })}
      </ul>

    </div>
  );
};

interface IAdView {}

const AdDetails = ({ad, cars}:{ad:AdItem, cars:any}) => {
    var context = useContext(AppContext);
    
    return <div className="item-review light-shadow-bg">
      <h3 className="widget-border-title">
          {ad.category.autocar ? context.langdata["Ad Cars Details"] : <></>}
          {ad.category.property ? context.langdata["Ad Prop Details"] : <></>}
      </h3>

      {ad.category.autocar ? (
      <div className="light-box-content">
        <p>
          VIN: {ad.vin}
        </p>
        <p>
          Номер автомобиля: {ad.carnumber}
        </p>
        <p>
          Цвет: {cars.Edits && cars.Edits.CarColor ? cars.Edits.CarColor.find((item: any) => item.id == ad.color?.id)?.colorName_ru : ""}
        </p>
        <p>
          Пробег: {ad.mileage}
        </p>
        <p>
          Состояние автомобиля: {ad.state}
        </p>
        <p>
          Год выпуска: {ad.makeYear}
        </p>
        <p>
          Кузов: {cars.Edits && cars.Edits.CarBody ? cars.Edits.CarBody.find((item: any) => item.id == ad.cusov?.id)?.bodytype_ru : ""}
        </p>
        <p>
          Двигатель: {cars.Edits && cars.Edits.CarEngine ? cars.Edits.CarEngine.find((item: any) => item.id == ad.engine?.id)?.engine_ru : ""}
        </p>
        <p>
          Привод: {cars.Edits && cars.Edits.CarDrive ? cars.Edits.CarDrive.find((item: any) => item.id == ad.driveunit?.id)?.name_ru : ""}
        </p>
        <p>
          Коробка передач: {cars.Edits && cars.Edits.CarTransmission ? cars.Edits.CarTransmission.find((item: any) => item.id == ad.transmission?.id)?.transmission_ru : ""}
        </p>

        {ad.rudderposition>0 ? (
        <p>
          Положение руля: {ad.rudderposition == 1 ? context.langdata["Left Rudder"] : context.langdata["Right Rudder  "]}
        </p>
        ) : <></>}

      </div> ) : <></>}

      {ad.category.property ? ( <div className="light-box-content">
        <p>
          Адрес недвижимости: {ad.address_ru}
        </p>
        <p>
          Недвижимость: {ad.proptype}
        </p>
        <p>
          Состояние: {ad.propstate}
        </p>
        <p>
          Этаж: {ad.level} из {ad.levelsInHome}
        </p>
        <p>
          Количество комнат: {ad.roomsCount}
        </p>
        <p>
          Балкон {ad.balcony ? "ДА" : "НЕТ"}, Лоджия {ad.loggia ? "ДА" : "НЕТ"}, Гардероб {ad.wardrobe ? "ДА" : "НЕТ"}, Панорамные окна {ad.panoramicwindows ? "ДА" : "НЕТ"}
        </p>
        <p>
          Площадь общая {ad.totalarea}, жилая {ad.livingspace}
        </p>
        <p>
          Высота потолков {ad.сeilingheight}
        </p>
        <p>
          Санузел {ad.bathroom}
        </p>
        <p>
            окна во двор {ad.windowstothecourtyard ? "ДА" : "НЕТ"}, окна на улицу {ad.windowstothecourtyard ? "ДА" : "НЕТ"}, окна на солнечную сторону {ad.windowsonthesunnyside ? "ДА" : "НЕТ"}
        </p>
        <p>
            Кухня {ad.kitchen ? "ДА" : "НЕТ"}, хранение одежду {ad.clothesstorage ? "ДА" : "НЕТ"}, спальные места {ad.sleepingplaces ? "ДА" : "НЕТ"}
        </p>
        <p>
            Кондиционер {ad.airconditioner ? "ДА" : "НЕТ"}, холодильник {ad.fridge ? "ДА" : "НЕТ"}, стиральная машина {ad.washingmachine ? "ДА" : "НЕТ"}, посудомоечная машина {ad.dishwasher ? "ДА" : "НЕТ"}, водонагреватель {ad.waterheater ? "ДА" : "НЕТ"}
        </p>
        <p>
            Ремонт {ad.repair}
        </p>
        </div>) : <></>}
    </div>
}

const LocationMap = () => {

  mapboxgl.accessToken = 'pk.eyJ1IjoiaXZrb21lIiwiYSI6ImNsOGN5Mm96NTBuanczdnE5dzAxNXV3eWUifQ.y7jzCxJf2lsg5hFTaKH_VQ';

  var context = useContext(AppContext);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(9);

  useEffect(() => {
      if (map.current) return; // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: zoom
      });
  });

  return (
    <div className="item-google-map light-shadow-bg">
      <h3 className="widget-border-title">{context.langdata["Location"]}</h3>
      <div className="google-map light-box-content">
        <div ref={mapContainer} className="map-container" style={{ width: "100%", height: "460px" }} />
      </div>
    </div>
  );
};

interface IReviewProps {
    id: string
    context: AppContextData;
    reviews?: Review[];
    LoadMoreViews?: () => void;
}

const RelatedAds = ({context}:IReviewProps) => {
  
  var adslist: AdItem[] = [];

  if (adslist.length > 0) {
    return (
      <div className="item-related-product light-shadow-bg">
        <div className="flex-heading-layout2">
          <h3 className="widget-border-title">
            {context.langdata["Related Ads"]}
          </h3>
          <div id="owl-nav1" className="smart-nav-layout1">
            <span className="rt-prev">
              <i className="fas fa-angle-left"></i>
            </span>
            <span className="rt-next">
              <i className="fas fa-angle-right"></i>
            </span>
          </div>
        </div>
        <div className="light-box-content">
          <div
            className="rc-carousel"
            data-loop="true"
            data-items="4"
            data-margin="30"
            data-custom-nav="#owl-nav1"
            data-autoplay="false"
            data-autoplay-timeout="3000"
            data-smart-speed="1000"
            data-dots="false"
            data-nav="false"
            data-nav-speed="false"
            data-r-x-small="1"
            data-r-x-small-nav="false"
            data-r-x-small-dots="false"
            data-r-x-medium="2"
            data-r-x-medium-nav="false"
            data-r-x-medium-dots="false"
            data-r-small="2"
            data-r-small-nav="false"
            data-r-small-dots="false"
            data-r-medium="2"
            data-r-medium-nav="false"
            data-r-medium-dots="false"
            data-r-large="3"
            data-r-large-nav="false"
            data-r-large-dots="false"
            data-r-extra-large="3"
            data-r-extra-large-nav="false"
            data-r-extra-large-dots="false"
          >
            {adslist.map((item, index) => {
              return (
                <div className="product-box-layout1 box-shadwo-light mg-1">
                  <div className="item-img">
                    <a href="single-product1.html">
                      <img
                        src="/media/product/product1.jpg"
                        alt="Product"
                      />
                    </a>
                  </div>
                  <div className="item-content">
                    <h3 className="item-title">
                      <a href="single-product1.html">
                        HD 27 inch Mac 1 year used only
                      </a>
                      <span>New</span>
                    </h3>
                    <ul className="entry-meta">
                      <li>
                        <i className="far fa-clock"></i>4 months ago
                      </li>
                      <li>
                        <i className="fas fa-map-marker-alt"></i>Port Chester,
                        New York
                      </li>
                    </ul>
                    <div className="item-price">
                      <span className="currency-symbol">$</span>
                      2,500
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return <></>
};

const Raiting = ({startraiting, onChange}:{startraiting:number, onChange:(raiting:number)=>void}) => {

  var [tempraiting, SetTempRaiting] = useState(startraiting);
  var [raiting, SetRatinig] = useState(startraiting);

  useEffect(()=>{
    onChange(raiting)
  }, [raiting])


  return <>
      <a href="javascript:void(0)"><i className="fa fa-star RaitingStar" onClick={()=>SetRatinig(1)} onMouseLeave={()=>SetTempRaiting(0)} onMouseEnter={()=>SetTempRaiting(1)} style={{fontSize:30, color: raiting>=1 || tempraiting>=1 ? "#f85c70" : ""}}></i></a>
      <a href="javascript:void(0)"><i className="fa fa-star RaitingStar" onClick={()=>SetRatinig(2)} onMouseLeave={()=>SetTempRaiting(0)} onMouseEnter={()=>SetTempRaiting(2)} style={{fontSize:30, color: raiting>=2 || tempraiting>=2 ? "#f85c70" : ""}}></i></a>
      <a href="javascript:void(0)"><i className="fa fa-star RaitingStar" onClick={()=>SetRatinig(3)} onMouseLeave={()=>SetTempRaiting(0)} onMouseEnter={()=>SetTempRaiting(3)} style={{fontSize:30, color: raiting>=3 || tempraiting>=3 ? "#f85c70" : ""}}></i></a>
      <a href="javascript:void(0)"><i className="fa fa-star RaitingStar" onClick={()=>SetRatinig(4)} onMouseLeave={()=>SetTempRaiting(0)} onMouseEnter={()=>SetTempRaiting(4)} style={{fontSize:30, color: raiting>=4 || tempraiting>=4 ? "#f85c70" : ""}}></i></a>
      <a href="javascript:void(0)"><i className="fa fa-star RaitingStar" onClick={()=>SetRatinig(5)} onMouseLeave={()=>SetTempRaiting(0)} onMouseEnter={()=>SetTempRaiting(5)} style={{fontSize:30, color: raiting>=5 || tempraiting>=5 ? "#f85c70" : ""}}></i></a>
  </>
}

const AddReview = ({id, context}:IReviewProps) => {

  var user = useSelector((state: any) => state.user);
  var [caption, SetCaption] = useState("");
  var [review, SetReview] = useState("");
  var [rating, SetRating] = useState(0);
  var [dealdone, SetDealDone] = useState<Boolean | null>(null);

  const [AddReview] = useMutation(graphql.mutations.ADD_REVIEW,
    {
        fetchPolicy: "network-only",
        onCompleted:(data)=>
        {
            //context.dispatch(ReduxActions.favorites.ToggleFavoriteCountry(adid, data.toggleFavorite))
        },
        onError: (error) =>
        {
            
        }
    });    

  const RaitingSet = (raiting:number) => {
    SetRating(raiting)
  }

  const SendReview = (e:any) => {
      e.stopPropagation()
      e.preventDefault()
      AddReview({variables:{id: id, caption: caption, review:review, rating:rating, lang:"", completed:false}})
  }

  return (user ? 
    <div className="item-review light-shadow-bg">
      <h3 className="widget-border-title">
        {context.langdata["Leave a Review"]}
      </h3>
      <div className="light-box-content">
        <form action="#">
          <div className="item-text">
            {
              context.langdata[
                "Your email address will not be published. Required fields are marked"
              ]
            }{" "}
            *
          </div>
          
          {!user ? (<>
          <div className="form-group">
            <label>{context.langdata["Your Name"]} *</label>
            <input type="text" className="form-control" name="name" value={user?.UserF +" "+ user?.UserI} />
          </div>
          <div className="form-group">
            <label>{context.langdata["Your Email"]} *</label>
            <input type="email" className="form-control" name="email" value={user?.email} />
          </div></> ) : (<></>)}

          <div className="form-group">
            <label>{context.langdata["Review Title"]} *</label>
            <input type="text" className="form-control" name="review" value={caption} onChange={(e)=>SetCaption(e.target?.value ?? "")} />
          </div>
          <div className="form-group">
            <label>{context.langdata["Your Review"]} *</label>
            <textarea
              className="form-control textarea"
              name="message"
              id="message"
              value={review} onChange={(e)=>SetReview(e.target?.value ?? "")}
              style={{ columnCount: 30 }}
            ></textarea>
          </div>
          <div className="form-group">
            <label>{context.langdata["Rating"]} *</label>
            <div className="w-100 d-block">
              <Raiting startraiting={rating} onChange={RaitingSet} />
            </div>
          </div>
          <div className="form-group">
            <label>{context.langdata["Deal completed"]} *</label>
             
            <div className="w-100 d-block">
              <button className={dealdone == true ? "item-btn mr-2" : "item-btn item-btn-hover mr-2"} onClick={(e)=>{
                e.preventDefault()
                e.stopPropagation()
                SetDealDone(true)}}>
                {context.langdata["Yes"]}
              </button>

              <button className={dealdone == false ? "item-btn" : "item-btn item-btn-hover"} onClick={(e)=>{
                e.preventDefault()
                e.stopPropagation()
                SetDealDone(false)}}>
                {context.langdata["No"]}
              </button>
            </div>
          </div>
          <div className="form-group text-center">
            <button className="item-btn" onClick={SendReview}>
              {context.langdata["SUBMIT"]}
            </button>
          </div>
        </form>
      </div>
    </div> : <></>
  );
};

const Reviews = ({context, reviews, LoadMoreViews}:IReviewProps) => {
  return reviews==undefined ? <></> : <div className="item-review light-shadow-bg">
    <h3 className="widget-border-title">
      {context.langdata["Reviews list"]}
    </h3>
    <div className="light-box-content">
      
      {reviews?.map((item, index) => {
          return <div className="blog-box-layout2">
            <div className="item-content">
                <ul className="blog-entry-meta">
                    <li><i className="far fa-calendar-alt"></i><Moment format="DD.MM.YYYY HH:mm">{item.createdAt}</Moment></li>
                    <li><i className="fas fa-user"></i><a href="javascript:void(0)">{item.owner?.account ?? ""}</a></li>
                </ul>
                {item.review_ru}
            </div>
         </div>
      })}
      
      <form className="text-center" >
        <div className="form-group">
            <a href="javascript:void(0);" className="item-btn" onClick={(e)=>{e.stopPropagation(); if (LoadMoreViews) LoadMoreViews()}}>
              {context.langdata["LOAD MORE"]}
            </a>
        </div>
      </form>
    </div>

  </div>
}

const AdView = (props: IAdView) => {
  //var [aditem, setAditem] = useState<AdItem>()
  
  const currency = useSelector((state:any)=>state.currency)
  const categories = useSelector((state: any) => state.categories)
  var userfavorites = useSelector((state:any)=>state.userfavorites)
  const cars = useSelector((state: any) => state.cars)

  let { adid } = useParams();
  var context = useContext(AppContext);
  
  var [aditem, refreshAd, addview] = useAd(adid);
    
  var [costincurrency, SetCostInCurrency] = useState(0);
  var [features, SetFeatures] = useState<{list1:string[], list2:string[]}>()
  var [fullCategory, SetFullCategory] = useState<CategoryItem[]>()
  var [marka, SetMarka] = useState<Marka>()
  var [model, SetModel] = useState<Model>()
  var [generation, SetGeneration] = useState<Generation>()
  var [InFav, SetInFav] = useState(false)
  
  var [adreviews, refreshReviews] = useReviews(adid, 5);

  const [ToggleVavorite] = useMutation(graphql.mutations.TOGGLE_FAVORITE,
    {
        fetchPolicy: "network-only",
        onCompleted:(data)=>
        {
          if (adid)
          {
            context.dispatch(ReduxActions.favorites.ToggleFavoriteCountry(adid, data.toggleFavorite))
          } 
        },
        onError: (error) =>
        {
            
        }
    });    
  
  const HasFeatures = useCallback( (item: AdItem) => {
      return item.features_ru || item.address_eng || item.address_greek || item.address_tur || item.address_deutsch;
  }, [aditem])

  const FeaturesList = useCallback( (item: AdItem) => {
    var features = "";
    var arr1:string[] = [], arr2:string[] = [];
    switch (context.lang)
    {
      case "ru":
          features =  item.features_ru;
          break;
      case "eng":
          features =  item.features_eng;
          break;
      case "tur":
          features =  item.features_tur;
          break;
      case "greek":
          features =  item.features_greek;
          break;
      case "greek":
          features =  item.features_deutsch;
          break;              
    }

    features.trim().split("\n").map((item:string, index) => {
       if (index % 2 == 0 && item!="") arr1.push(item);
       if (index % 2 !== 0 && item!="") arr2.push(item);
    });

    return {list1: arr1, list2: arr2};
}, [aditem, context.lang])

  const AddToFavorites = (id:String) => {
    ToggleVavorite({variables:{id:id}})
  }

  const ReportAbuse = (id:String) => {

  }

  const LoadMoreViews = () =>
  {

  }

  useEffect(() => {
    refreshAd(adid);
    addview(adid);
  }, []);

  useEffect(() => {
    var ad = aditem as AdItem
    if (ad)
    {
      var newaditem = ConvertAdCurrency(ad, context.currency, currency) ;
      if (newaditem)
      {
        SetCostInCurrency(newaditem?.recalculatedcost ?? 0);
      }
    }
  }, [aditem, currency, context.currency])

  useEffect(() => {
    if (aditem)
    {
      var features = FeaturesList(aditem);
      SetFeatures({list1:features.list1, list2:features.list2});

      var fullCategory = [aditem.category]
      var categoryItem = aditem.category.parent
      while (categoryItem!=undefined)
      {
          var subCategory = categories.find((item)=>item.id == categoryItem)
          if (categoryItem)
          {
            categoryItem = subCategory.parent
            fullCategory.push(subCategory)
          } else break
      }

      fullCategory.reverse()
      SetFullCategory(fullCategory)

      var marka1 = cars.Marks.find((item)=>item.id == aditem?.marka?.id)
      var model1 = cars.Models.find((item)=>item.id == aditem?.model?.id)
      var generation1 = cars.Generations.find((item)=>item.id == aditem?.generation?.id)

      SetMarka(marka1)
      SetModel(model1)
      SetGeneration(generation1)

      console.log(aditem)

    } else
    {
      SetFeatures(undefined);
    }
  }, [aditem, context.lang, categories, cars]);

  useEffect(() => {
    var infav = (userfavorites as Array<AdFavorites>)?.find(f=>f.ad.id == aditem?.id)!=undefined
    SetInFav(infav)
  }, [userfavorites, aditem])

  return (
    <>
      {aditem == undefined ? (
        <div className="d-block text-center">{context.langdata["Loading"]}...</div>
      ) : (
        <>
          <AdViewHeader store={false} item={aditem}></AdViewHeader>
          <section className="single-product-wrap-layout1 section-padding-equal-70 bg-accent">
            <div className="container">
              <div className="row">
                <div className="col-xl-9 col-lg-8">
                  <div className="single-product-box-layout1">
                    <div className="product-info light-shadow-bg">
                      <div className="product-content light-box-content">
                        {aditem.photos !== undefined ? (
                          <LighBoxGallery item={aditem}></LighBoxGallery>
                        ) : (
                          <></>
                        )}

                        {fullCategory ? (
                        <div className="single-entry-meta">
                          <h4>
                            {fullCategory.map((item, index) => {
                                if (context.lang == "ru" && item.title_ru) return item.title_ru + (index < fullCategory!!.length-1 ? " > " : "")
                                else if (context.lang == "en" && item.title_eng) return item.title_eng + (index < fullCategory!!.length-1 ? " > " : "")
                                else if (context.lang == "tur" && item.title_tur) return item.title_tur + (index < fullCategory!!.length-1 ? " > " : "")
                                else if (context.lang == "greek" && item.title_greek) return item.title_greek + (index < fullCategory!!.length-1 ? " > " : "")
                                else if (context.lang == "deutsch" && item.title_deutsch) return item.title_deutsch + (index < fullCategory!!.length-1 ? " > " : "")
                            })}
                            
                          </h4>
                        </div>) : (<></>)}

                        <div className="single-entry-meta" style={{margin:0, padding:0}}>
                          <h2>
                            {context.lang == "ru" ? aditem.title_ru : ""}
                            {context.lang == "en" ? aditem.title_eng : ""}
                            {context.lang == "tur" ? aditem.title_tur : ""}
                            {context.lang == "greek" ? aditem.title_greek : ""}
                            {context.lang == "deutsch"
                              ? aditem.title_deutsch
                              : ""}
                          </h2>
                        </div>

                        <div className="single-entry-meta" style={{margin:0, padding:0}}>
                          <h5>
                              {(context.lang == "ru" && marka && marka.Marka_ru) ? marka.Marka_ru + ", " : ""}
                              {(context.lang == "ru" && model && model.Model_ru) ? model.Model_ru + ", " : ""}
                              {(context.lang == "ru" && generation && generation.Generation_ru) ? generation.Generation_ru : ""}

                              {(context.lang == "en" && marka && marka.Marka_eng) ? marka.Marka_eng + ", " : ""}
                              {(context.lang == "en" && model && model.Model_eng) ? model.Model_eng + ", " : ""}
                              {(context.lang == "en" && generation && generation.Generation_eng) ? generation.Generation_eng : ""}

                              {(context.lang == "tur" && marka && marka.Marka_tur) ? marka.Marka_tur + ", " : ""}
                              {(context.lang == "tur" && model && model.Model_tur) ? model.Model_tur + ", " : ""}
                              {(context.lang == "tur" && generation && generation.Generation_tur) ? generation.Generation_tur : ""}

                              {(context.lang == "greek" && marka && marka.Marka_greek) ? marka.Marka_greek + ", " : ""}
                              {(context.lang == "greek" && model && model.Model_greek) ? model.Model_greek + ", " : ""}
                              {(context.lang == "greek" && generation && generation.Generation_greek) ? generation.Generation_greek : ""}

                              {(context.lang == "deutsch" && marka && marka.Marka_deutsch) ? marka.Marka_deutsch + ", " : ""}
                              {(context.lang == "deutsch" && model && model.Model_deutsch) ? model.Model_deutsch + ", " : ""}
                              {(context.lang == "deutsch" && generation && generation.Generation_deutsch) ? generation.Generation_deutsch : ""}
                          </h5>
                        </div>

                        

                        <div className="single-entry-meta">
                          <ul>
                            <li>
                              <i className="far fa-clock"></i>
                              <Moment format="DD.MM.YYYY HH:mm">{aditem.createdAt}</Moment>
                            </li>
                            <li>
                              <i className="fas fa-map-marker-alt"></i>
                              {aditem.location}
                            </li>
                            <li>
                              <i className="far fa-eye"></i>
                              {aditem.views} {context.langdata["views"]}
                            </li>
                          </ul>
                          <div className="item-condition">New</div>
                        </div>
                        <div className="item-details">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#details"
                                role="tab"
                                aria-selected="true"
                              >
                                {context.langdata["Details"]}
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#features"
                                role="tab"
                                aria-selected="false"
                              >
                                {context.langdata["Features"]}
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              className="tab-pane fade show active"
                              id="details"
                              role="tabpanel"
                            >

                                {context.lang == "ru"
                                  ? <div dangerouslySetInnerHTML={{__html: aditem.description_ru.split("\n").join("<br/>")}} />
                                  : ""}
                                {context.lang == "en"
                                  ? <div dangerouslySetInnerHTML={{__html: aditem.description_eng.split("\n").join("<br/>")}} />
                                  : ""}
                                {context.lang == "tur"
                                  ? <div dangerouslySetInnerHTML={{__html: aditem.description_tur.split("\n").join("<br/>")}} />
                                  : ""}
                                {context.lang == "greek"
                                  ? <div dangerouslySetInnerHTML={{__html: aditem.description_greek.split("\n").join("<br/>")}} />
                                  : ""}
                                {context.lang == "deutsch"
                                  ? <div dangerouslySetInnerHTML={{__html: aditem.description_deutsch.split("\n").join("<br/>")}} />
                                  : ""}
                              
                            </div>
                            <div
                              className="tab-pane fade"
                              id="features"
                              role="tabpanel"
                            >
                              {features?.list1 || features?.list2 ? (
                              <div className="item-features">
                                <div className="row">
                                
                                {features?.list1 ? (
                                <div className="col-md-6">
                                  <ul className="features-list">
                                    {features?.list1.map((item, index) => {
                                      return <li key={index}>{item}</li>  
                                    })}
                                  </ul>
                                </div>
                                ): (<></>)}
                                
                                {features?.list2 ? (
                                <div className="col-md-6">
                                  <ul className="features-list">
                                    {features?.list2.map((item, index) => {
                                      return <li key={index}>{item}</li>  
                                    })}
                                  </ul>
                                </div>
                                ): (<></>)}

                                </div>
                              </div> ): (<></>)}


                            </div>
                          </div>
                        </div>
                        <div className="item-action-area">
                          <ul>
                            {InFav ? (
                            <li className="inline-item">
                                <a href="javascript:void(0);" onClick={()=>AddToFavorites(aditem?.id ?? "")} style={{color:"#f85c70"}}>
                                <i className="far fa-solid fa-heart" style={{color:"#f85c70"}}></i>
                                {context.langdata["Remove from Favourites"]}
                              </a>
                            </li> ): (
                              <li className="inline-item">
                              <a href="javascript:void(0);" onClick={()=>AddToFavorites(aditem?.id ?? "")}>
                                <i className="far fa-heart"></i>
                                {context.langdata["Add to Favourites"]}
                              </a>
                            </li>
                            )}
                            <li className="inline-item">
                              <a href="javascript:void(0);" onClick={()=>ReportAbuse(aditem?.id ?? "")}>
                                <i className="fas fa-exclamation-triangle"></i>
                                {context.langdata["Report Abuse"]}
                              </a>
                            </li>
                            <li className="item-social">
                              <span className="share-title">
                                <i className="fas fa-share-alt"></i>
                                {context.langdata["Share"]}:
                              </span>
                              <a href="javascript:void(0);" onClick={()=>{
                                window.open(`https://www.facebook.com/sharer.php?u=https://avgu.com/ad/${adid}`,"sharer","status=0,toolbar=0,width=650,height=500");
                              }} className="bg-facebook">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href="javascript:void(0);" className="bg-twitter" onClick={()=>
                              {
                                window.open(`https://twitter.com/intent/tweet?text=ТАЙТЛ_СТРАНИЦЫ. ОПИСАНИЕ. https://avgu.com/ad/${adid}`,"sharer","status=0,toolbar=0,width=650,height=500");
                              }}>
                                <i className="fab fa-twitter"></i>
                              </a>
                              <a href="javascript:void(0);" className="bg-twitter" onClick={()=>
                              {
                                window.open(`https://telegram.me/share/url?url=https://avgu.com/ad/${adid}`,"sharer","status=0,toolbar=0,width=650,height=500");
                                }}>
                                <i className="fab fa-telegram"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    {aditem.category.autocar || aditem.category.property ? <AdDetails ad={aditem} cars={cars}></AdDetails> : <></>}

                    {aditem.dontshowmap ? <></> : <LocationMap></LocationMap>}

                    <RelatedAds id={adid ?? ""} context={context}></RelatedAds>
                    {aditem.reviews.length>0 ? <Reviews context={context} id={adid ?? ""} reviews={aditem.reviews} LoadMoreViews={LoadMoreViews}></Reviews> : <></>}
                    <AddReview id={adid ?? ""} context={context}></AddReview>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 sidebar-break-md sidebar-widget-area">
                  <div className="widget-lg widget-price">
                    <div className="item-price">
                      <span className="currncy-symbol mr-2">
                        {GetCurrencySymvol(aditem.currency)}
                      </span>
                      {currencyFormat(aditem.cost)}
                    </div>
                  </div>
                  
                  {aditem.currency.toUpperCase() != context.currency.toUpperCase() ?
                  <div className="widget-lg widget-price">
                    <div className="item-price">
                      <span className="currncy-symbol mr-2">
                        {GetCurrencySymvol(context.currency)}
                      </span>
                      {currencyFormat(costincurrency)}
                    </div>
                  </div> : <></>}

                  <div className="widget-lg widget-author-info widget-light-bg">
                    <h3 className="widget-border-title">
                      {context.langdata["Seller Information"]}
                    </h3>
                    <div className="author-content">
                      <div className="author-name">
                        <div className="item-img">
                          <img
                            src={aditem.user.avatar || "/media/figure/author.jpg"}
                            alt="author"
                          />
                        </div>
                        <h4 className="author-title">
                          <Link to={`/profile/${aditem.user.id}`}>
                            {aditem.user.account}
                          </Link>
                        </h4>
                      </div>
                      <div className="author-meta">
                        <ul>
                          <li>
                            <i className="fas fa-map-marker-alt"></i>
                            {aditem.user.account}
                          </li>
                            <li>
                              <Link to={`/shop/${aditem.user.id}`}>
                              <i className="fas fa-shopping-basket"></i> {context.langdata["View Store"]}
                              </Link>
                            </li>
                        </ul>
                      </div>
                      <div
                        className="phone-number classima-phone-reveal not-revealed"
                        data-phone="6735568568"
                      >
                        <div className="number">
                          <i className="fas fa-phone"></i>
                          <span>{aditem.phones}</span>
                        </div>
                        <div className="item-text">
                          {context.langdata["Click to reveal phone number"]}
                        </div>
                      </div>
                      <div className="author-mail">
                        <a
                          href="#"
                          className="mail-btn"
                          data-toggle="modal"
                          data-target="#author-mail"
                        >
                          <i className="fas fa-envelope"></i>
                          {context.langdata["Email to Seller"]}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="widget-lg widget-safty-tip widget-light-bg">
                    <h3 className="widget-border-title">
                      {context.langdata["Safety Tips for Buyers"]}
                    </h3>
                    <div className="safty-tip-content">
                      <ul>
                        <li>
                          {context.langdata["Meet seller at a public place"]}
                        </li>
                        <li>
                          {context.langdata["Check The item before you buy"]}
                        </li>
                        <li>
                          {
                            context.langdata[
                              "Pay only after collecting The item"
                            ]
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AdView;
